import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'external',
    pathMatch: 'full',
  },
  {
    path: 'external/:sellerId/:pointId',
    loadChildren: () => import('./external/external.module').then((m) => m.ExternalModule),
  },
  {
    path: 'internal/:sellerId/:pointId',
    loadChildren: () => import('./internal/internal.module').then((m) => m.InternalModule),
  },
  {
    path: 'loggedCart/:pointId',
    loadChildren: () => import('./cart/cart.module').then((m) => m.CartModule),
  },
  // {
  //   path: 'checkout',
  //   loadChildren: () =>
  //     import('../../../gen-checkout/src/app/remote-entry/entry.module').then((m) => m.RemoteEntryModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
